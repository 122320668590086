<template>
  <v-card>
    <v-card-title>
      Owners
      <div class="flex-grow-1"></div>
      <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      v-if="owners.length"
      :headers="headers"
      :items="owners"
      :items-per-page="30"
      item-key="id"
      class="elevation-1"
      :search="search"
      sort-by="approved"
    >
      <template v-slot:body="{items}">
        <tbody>
          <template v-for="item in items">
            <tr :key="item.id">
              <td>{{ capitalize(item.name) }}</td>
              <td>{{ capitalize(item.distributors) }}</td>
              <td>{{ item.owner.first }}</td>
              <td>{{ item.owner.last }}</td>
              <td>{{ item.owner.email }}</td>
              <td>{{ getSensorsResult(item.sensors) }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "stores",
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        {
          text: "Distributor",
          value: "distributors"
        },
        {
          text: "First",
          value: "first"
        },
        {
          text: "Last",
          value: "last"
        },
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Sensors",
          value: "sensors"
        }
      ]
    };
  },
  async created() {
    if (this.isSuper) {
      this.$store.dispatch("owner/allOwners");
    } else if (this.isDistributor) {
      this.$store.dispatch(
        "owner/getDistributorOwners",
        this.userDistributorName
      );
    }
    // } else {
    //   console.log("no users");
    // }
  },
  computed: {
    ...mapState("user", ["user", "users"]),
    ...mapState("owner", ["owners"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isOwner",
      "isAdmin",
      "userDistributorName"
    ])
  },
  methods: {
    getSensorsResult(sensors) {
      let allSensors = sensors
        .map(item => {
          return ` ${item.label}`;
        })
        .sort((a, b) => a - b);
      return `${sensors.length} - [ ${[...allSensors]} ]`;
    },
    async approveUser(id, email) {
      await this.$store.dispatch("user/approveUserRegistration", { id, email });
    },
    async disableUser(id) {
      await this.$store.dispatch("user/disableUserRegistration", id);
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
</style>